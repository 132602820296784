@use '@/styles/global' as g;

$default-desktop-features-per-row: 3;
$default-mobile-features-per-row: 2;

@function getCellWidth($features-per-row) {
    $feature-width-colum-gap-offset: calc((g.$grid-column-gap * ($features-per-row - 1)) / $features-per-row);
    @return calc((100% / #{$features-per-row}) - #{$feature-width-colum-gap-offset});
}

@mixin fixedGridCells {
    justify-content: flex-start;

    .featureItem {
        flex-grow: 0;

        figure {
            aspect-ratio: 1 / 1;
            justify-content: center;
            padding: map-get(g.$page-layout-spacing, 'tiny');
        }

        &:first-child {
            padding-top: 0;
        }

        img {
            object-fit: contain;
            max-width: none;
        }
    }
}

.optionsList {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: g.$grid-column-gap;

    a {
        width: 100%;
        text-decoration: none;
    }

    figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
        color: g.$black;
    }

    img {
        max-width: var(--feature-image-max-width);
    }

    figcaption {
        @include g.font-size(14px);

        &.heavyText {
            @include g.font-size(16px);
        }
    }

    @include g.bp-max(md) {
        &.fixedRatioMobileLayoutCell {
            @include fixedGridCells();
            gap: 4px;

            .featureItem {
                width: 100%;

                figure {
                    aspect-ratio: 1 / 0.25;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: map-get(g.$page-layout-spacing, 'micro');
                    padding: 16px;
                }

                figcaption {
                    text-align: left;
                }

                img {
                    flex-shrink: 0;
                    height: 100%;
                    width: 36%;
                }
            }
        }
    }
}

.featureItem {
    width: getCellWidth(var(--mobile-features-per-row, $default-mobile-features-per-row));

    @include g.bp(md) {
        width: getCellWidth(var(--desktop-features-per-row, $default-desktop-features-per-row));
    }
}

.fixedGridCellsMobile {
    @include g.bp-max(md) {
        @include fixedGridCells();
    }
}

.fixedGridCellsDesktop {
    @include g.bp(md) {
        @include fixedGridCells();
    }
}