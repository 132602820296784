// To be replaced with UI toolkit tokens

@use '.' as g;

//CSS Vars
:root {
    --font-standard: 'Helvetica Now', arial, sans-serif;

    // Primary temp names
    --black: #000;
    --white: #fff;

    // Fonts temp names
    --temp-line-height-paragraph-medium: 1.5;

    // Dropdown Overlay temp names
    --temp-dropdown-overlay: rgba(0, 0, 0, 0.3);

    // Explore All image background color
    --overlay-background-color: rgba(0, 0, 0, 0.4);

    //Modal
    --temp-modal-background-color: rgba(24, 24, 24, 0.7);

    // Colors
    // Add more colors as needed
    --subset-sxm-blue-border-accent: #79a5ff;

    --subset-brand-blue-background: #0000eb;
    --subset-light-grey-background: #f5f5f5;

    // Borders
    --border-radius-10: 10px;

    --border-width-1: 1px;
    --border-width-2: 2px;
    --border-width-2-5: 2.5px;
    --border-width-3: 3px;
    --border-width-4: 4px;
    --border-width-6: 6px;
    --border-width-8: 8px;
    --border-width-16: 16px;

    // Icons
    --prod-icon-large: 24px;

    // Temp W1 colors
    --gray-dark: #736e7d;
    --blue-secondary: #006ed7;
    --blue-light: #e9eef8;
}
