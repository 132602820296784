@use '@/styles/global' as g;

.card {
    border: solid var(--gray-light);
    padding: 32px;
    display: flex;
    flex-direction: column;

    @include g.bp(md) {
        flex-direction: row;
    }

    @include g.web2PageOverrides {
        border-radius: 24px;
    }

    &.verticalCardVariant {
        flex-direction: column;
    }

    &.horizontalCardVariant {
        justify-content: space-between;
    }

    &.stripVariant {
        border-width: 1px 0 0 0;
        padding: 24px 0 32px;
        justify-content: space-between;

        @include g.bp(md) {
            padding: 24px 0;
        }

        @include g.web2PageOverrides {
            border-radius: 0;
        }
    }
}

.leadingInfo {
    width: 100%;

    @include g.bp(md) {
        width: 30%;
    }

    .verticalCardVariant & {
        @include g.bp(md) {
            width: 100%;
        }
    }

    .horizontalCardVariant & {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include g.bp(md) {
            width: 43.65%;
        }
    }

    .leadingCta {
        display: none;

        .horizontalCardVariant & {
            @include g.bp(md) {
                display: block;
            }
        }
    }
}

.title {
    margin: 0 0 2px;
    padding-right: 28px;

    .horizontalCardVariant & {
        @include g.font-size(14px);
        @include g.set-font-weight('bold');
        line-height: 22px;
    }
}

.price {
    margin-bottom: 4px;
    @include g.set-font-weight('black');
    @include g.font-size(44px);
    line-height: 1.04;

    .billingPeriod {
        @include g.font-size(14px);
        margin-right: 8px;
    }

    .horizontalCardVariant &,
    .horizontalCardVariant & .billingPeriod {
        @include g.font-size(28px);
        margin-top: 12px;
        margin-bottom: 8px;
    }

    .stripVariant & {
        @include g.font-size(16px);
        line-height: 1.5;

        .billingPeriod {
            @include g.font-size(14px);
        }
    }
}

.subText {
    color: var(--gray-dark);
    @include g.font-size(12px);
    line-height: 1.5;
    margin-bottom: map-get(g.$page-layout-spacing, 'micro');

    .stripVariant & {
        padding-right: 28px;
        margin: 0 0 16px;

        @include g.bp(md) {
            margin-bottom: 0;
        }
    }
}

.iconList {
    display: none;
    list-style-type: none;
    margin-bottom: 18px;
    padding: 0;

    .iconListItem {
        display: flex;
        align-items: flex-start;
        width: 22%;
        margin: 0 8px 0 0;

        &:last-of-type {
            margin-right: 0;
        }

        @include g.bp(lg) {
            width: auto;
        }
    }

    .iconWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
    }

    .icon {
        height: 48px;
        width: 48px;
        display: inline-block;
    }

    .iconLabel {
        text-align: center;
        width: 64px;
        color: var(--gray-charcoal);
        @include g.font-size(12px);
        @include g.set-font-weight('light');
        line-height: 18px;
    }

    .verticalCardVariant & {
        display: flex;
        flex-wrap: wrap;
    }
}

.trailingInfo {
    width: 100%;

    .horizontalCardVariant & {
        @include g.bp(md) {
            width: 47%;
        }
    }

    .stripVariant & {
        display: flex;
        flex-direction: row;

        @include g.bp(md) {
            width: 40%;
            padding-right: 24px;
        }
    }
}

.description {
    line-height: 1.57;
    padding-right: 5px;
    margin-bottom: map-get(g.$page-layout-spacing, 'micro');

    .stripVariant & {
        margin: 0;
        padding-right: 0;

        @include g.bp(md) {
            padding-right: 65px;
        }
    }
}

.featureList {
    display: none;

    .horizontalCardVariant & {
        display: block;
        line-height: 22px;
        @include g.font-size(14px);
        @include g.set-font-weight('light');
    }
}

.trailingCta {
    display: none;
    width: 100%;
    height: fit-content;

    .horizontalCardVariant & {
        display: block;
        margin-top: 0;

        @include g.bp(md) {
            display: none;
        }
    }

    .stripVariant & {
        display: block;
        margin-top: 32px;

        @include g.bp(md) {
            width: 30%;
            max-width: 200px;
            margin-top: 0;
        }
    }
}