@use './functions' as f;

$font-standard: var(--brand-font-family-primary, 'HCo Gotham', Arial, Helvetica, sans-serif);
$font-condensed: 'HCo Gotham Narrow', Arial, Helvetica, sans-serif;
$font-alt: 'GT America', Arial, Helvetica, sans-serif;

$fonts-weight: (
    $font-standard: (
        'light': 300,
        'book': 400,
        'medium': 500,
        'bold': 700,
        'black': 800,
        ),
        $font-condensed: (
            'book': 400,
            'medium': 500,
            'black': 800,
            ),
            $font-alt: (
                'light': 300,
                'medium': 500,
                'bold': 700,
                'black': 800,
                ),
            );

        // Grid config
        //  =================================
        $layout-max-width: 1336px;
        $grid-columns: 12;
        $grid-column-gap: 24px;

        $grid-paddings: (
            xs: 16px,
            sm: 18px,
            md: 36px,
            lg: 56px,
        );

    // Breakpoints
    // =================================
    // If updating these, also update the breakpoints in src/constants/responsiveness.ts
    $breakpoints: (
        xs: 0,
        sm: 540px,
        md: 768px,
        lg: 1024px,
        xl: 1336px,
        nav: 1115px,
        nav-logo: 375px,
    );

// Spacing
// =================================
//

$spacings: 2, 4, 8, 12, 16, 20, 24, 32, 40;

$header-margin-top: 0;
$header-margin-bottom: 0;
$header-styles: (
    sm: (h1: (font-size: f.calculate-rem(36px),
            line-height: 1.0558,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h2: (font-size: f.calculate-rem(30px),
            line-height: 1.13,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h3: (font-size: f.calculate-rem(27px),
            line-height: 1.14,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h4: (font-size: f.calculate-rem(24px),
            line-height: 1.12,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h5: (font-size: f.calculate-rem(18px),
            line-height: 1.22,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h6: (font-size: f.calculate-rem(16px),
            line-height: 1.43,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
    ),
    md: (h1: (font-size: f.calculate-rem(48px),
            line-height: 1.04,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h2: (font-size: f.calculate-rem(38px),
            line-height: 1.05,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h3: (font-size: f.calculate-rem(30px),
            line-height: 1.13,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h4: (font-size: f.calculate-rem(26px),
            line-height: 1.11,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h5: (font-size: f.calculate-rem(20px),
            line-height: 1.2,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h6: (font-size: f.calculate-rem(16px),
            line-height: 1.43,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
    ),
    lg: (h1: (font-size: f.calculate-rem(59px),
            line-height: 0.98,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h2: (font-size: f.calculate-rem(44px),
            line-height: 1.04,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h3: (font-size: f.calculate-rem(32px),
            line-height: 1.12,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h4: (font-size: f.calculate-rem(28px),
            line-height: 1.1,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h5: (font-size: f.calculate-rem(20px),
            line-height: 1.2,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h6: (font-size: f.calculate-rem(16px),
            line-height: 1.43,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
    ),
    xl: (h1: (font-size: f.calculate-rem(70px),
            line-height: 1.02,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h2: (font-size: f.calculate-rem(48px),
            line-height: 1.04,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h3: (font-size: f.calculate-rem(32px),
            line-height: 1.12,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h4: (font-size: f.calculate-rem(28px),
            line-height: 1.1,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h5: (font-size: f.calculate-rem(20px),
            line-height: 1.2,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
        h6: (font-size: f.calculate-rem(16px),
            line-height: 1.43,
            letter-spacing: 0,
            font-weight: f.calculate-font-weight('black', $font-standard, $fonts-weight),
        ),
    ),
);

$w2-header-styles: (
    sm (h1: (font-size: f.calculate-rem(48px),
            margin-bottom: 24px,
        ),
        h2: (font-size: f.calculate-rem(40px),
            margin-bottom: 20px,
        ),
        h3: (font-size: f.calculate-rem(32px),
            margin-bottom: 16px,
        ),
        h4: (font-size: f.calculate-rem(24px),
            margin-bottom: 12px,
        ),
        h5: (font-size: f.calculate-rem(20px),
            margin-bottom: 10px,
        ),
        h6: (font-size: f.calculate-rem(16px),
            margin-bottom: 8px,
        ),
    ),
    lg (h1: (font-size: f.calculate-rem(80px),
            margin-bottom: 40px,
        ),
        h2: (font-size: f.calculate-rem(60px),
            margin-bottom: 30px,
        ),
        h3: (font-size: f.calculate-rem(44px),
            margin-bottom: 22px,
        ),
        h4: (font-size: f.calculate-rem(32px),
            margin-bottom: 16px,
        ),
        h5: (font-size: f.calculate-rem(24px),
            margin-bottom: 12px,
        ),
        h6: (font-size: f.calculate-rem(18px),
            margin-bottom: 9px,
        ),
    )
);

// Vertical spacing
// =================================
// The vertical rythym from the Design System.
// Used to make molecule and group spacing.
// These keys do not relate to the grid breakpoints below.
$page-layout-spacing: (
    mixtape: 16px,
    micro: 24px,
    tiny: 40px,
    short: 48px,
    grande: 56px,
    venti: 64px,
    trenta: 72px,
    jumbo: 80px,
    huge: 96px,
    gigantic: 104px,
);

// Button variables
// =================================
$button-max-width: 296px;

//Nav height
$nav-height: (
    xs: map-get($page-layout-spacing, grande),
    md: map-get($page-layout-spacing, trenta),
);