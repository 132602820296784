@use '@/styles/global' as g;

.list {
    position: relative;
    margin-bottom: var(--list-margin-bottom, 32px);
    padding: 0;
    @include g.font-size(12px);
    list-style-type: disc;
    margin-left: 15px;

    .item {
        position: relative;
        margin-bottom: 6px;
        padding-left: 7px;

        @include g.web2PageOverrides {
            border-radius: var(--rounding-large, 24px);
        }

        &:last-child {
            margin-bottom: 0;
        }

        .itemContentWrapper {
            display: flex;

            .markerSpace {
                @include g.font-size(12px);
                margin-right: 3px;
                margin-bottom: 0;
                width: 18px;
                flex: auto 0 0;
                display: none;
                align-items: center;
                height: 14px;
            }

            .itemContent {
                flex-grow: 1;
                display: flex;
                margin-left: var(--body-margin-left);
                align-items: center;
            }
        }
    }

    &.customIcon {
        list-style-type: none;
        margin-left: 0;

        .item {
            padding-left: 0;
        }

        .itemContentWrapper .markerSpace {
            display: flex;
            flex-shrink: 0;

            &:before {
                background-image: var(--marker-image);
                background-position: 0;
                background-repeat: no-repeat;
                background-size: contain;
                transform: translateY(2px);
                content: '';
                left: 0;
                top: var(--icon-top);
                width: var(--icon-width);
                height: var(--icon-height);
                position: absolute;
            }
        }
    }

    &.large {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
        list-style-type: none;
        margin-left: 0;

        .item {
            margin: 0 auto 4px 0;
            width: 100%;
            background-color: g.$white;
            padding: 24px 12px 24px 28px;

            .itemContentWrapper {
                width: 100%;
                flex-direction: column;

                .markerSpace {
                    @include g.font-size(16px);
                    height: 24px;
                }
            }

            @include g.bp(md) {
                min-height: 72px;
                padding: 16px 28px 16px 24px;

                .itemContentWrapper {
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;

                    .markerSpace {
                        width: 10%;
                        align-self: center;
                        flex-shrink: 0;
                    }
                }

                &:hover {
                    z-index: 2;
                }
            }
        }

        &.customIcon {
            list-style-type: none;

            .item {
                @include g.bp(md) {
                    padding-left: 12px;
                }
            }

            .markerSpace:before {
                display: block;
                position: relative;
                height: 100%;
            }
        }
    }
}

ol.list {
    list-style-type: none;
    margin-left: 0;

    .item {
        .itemContentWrapper .markerSpace {
            display: flex;
            align-items: center;

            &:before {
                display: none;
            }
        }
    }

    &.large .item {
        @include g.bp(md) {
            padding-left: 12px;
        }
    }
}

.fullWidth {
    width: 100%;

    &:hover {
        .itemContentWrapper .itemContent a {
            @include g.link-underline-properties;
        }
    }
}
