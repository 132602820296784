@use '@/styles/global' as g;

.body {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin-bottom: 40px;

    @include g.bp-max(md) {
        flex-direction: column-reverse;
    }
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
}