@use '@/styles/global' as g;

.title {
    text-align: center;
    color: g.$gray-dark;
    @include g.set-font-weight('bold');

    h2 {
        margin-bottom: 24px;
    }
}

.cardsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 26px;
    justify-items: center;

    &.stripVariant {
        gap: 0;
    }

    @include g.bp(md) {
        &.verticalCardVariant {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.card {
    .horizontalCardVariant & {
        width: 100%;
    }

    .verticalCardVariant & {
        width: 100%;

        >* {
            height: 100%;
        }
    }

    @include g.bp-max(md) {
        max-width: 366px;
    }
}