@use 'sass:math';
@use './functions' as f;
@use './variables' as v;
@use './color-palette' as c;

//Global mixins
@mixin box-sizing-reset {
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@mixin base-font-styles {
    color: c.$black;
    font-family: var(--brand-font-family-primary, v.$font-standard);
    scroll-behavior: smooth;
    @include font-size(14px);
}

@mixin base-image-styles {
    img {
        display: block;
        height: auto;
        width: 100%;
    }
}

@mixin button-reset {
    button {
        background: transparent;
        border-color: transparent;
        padding: 0;
        margin: 0;
        line-height: 1;
        font-family: v.$font-standard;
        @include font-size(13px);
        font-weight: f.map-deep-get(v.$fonts-weight, v.$font-standard, regular);
        color: inherit;
    }
}

@mixin cursor-styles {
    a,
    button {
        cursor: pointer;
    }
}

@mixin section-styles {
    // <section> === "Content Group" in CMS, "Group" in design
    section {
        @include group-spacing;

        &.no-padding {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

@mixin table-rtetable {
    table.rtetable {
        border-collapse: collapse;
        width: 100%;

        td {
            border: 1px solid c.$gray-medium;
            padding: 5px;
            @include font-size(14px);
        }
    }
}

@mixin img-rteimage {
    img.rteimage {
        width: auto;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
    }
}

// Map deep get
// =================================
// useage:
// map-deep-get($header-styles, small, h1, font-size)
// based on Headers _variables.scsvs

// @author Hugo Giraudel
// @access public
//
// @param {Map} $map - this is the the data object, basically a BIG variable in object notation.
// It's the reference that somewhere contains a value you want to get to.
//
// @param {Arglist} $keys - the number of keys determine the depth INTO the $map object.
// One key in one level into the $map object. Object notation equivalent would be Map.key1.key2
//
// @return {*} - returns ONE specified value

// map-get is built into SCSS - https://sass-lang.com/documentation/values/maps

//     @return $map;
// }

// Breakpoint include
// =================================
// usage:
// @include bp(md) { @content }
@mixin bp($breakpoint) {
    @if map-has-key(v.$breakpoints, $breakpoint) {
        @media (min-width: #{map-get(v.$breakpoints, $breakpoint)}) {
            @content;
        }
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.';
    }
}

// Breakpoint max-width (use in rare cases)
// =================================
// usage:
// @include bp-max(md) { @content }
@mixin bp-max($breakpoint) {
    @if map-has-key(v.$breakpoints, $breakpoint) {
        @media (max-width: #{map-get(v.$breakpoints, $breakpoint)-1}) {
            @content;
        }
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.';
    }
}

// Mixin doing a loop of object and adding property to go with value
// $map - object to loop
// $index - boolean to use index of item or 'key' as the name
// $prop - property to add to css
@mixin item-list($map, $index: false, $prop: 'none') {
    @each $item-name, $item in $map {
        @if not map-has-key($map, $item-name) {
            @warn ''#{$item-name}' is not a valid icon name';
        } @else {
            @if $prop == 'none' {
                @warn 'property option not included, please add property';
            } @else {
                @if $index ==false {
                    &-#{$item-name} {
                        #{$prop}: $item;
                    }
                } @else {
                    &-#{index($map, $item-name $item)} {
                        #{$prop}: $item;
                    }
                }
            }
        }
    }
}

// Font Declaration
// =================================
// usage:
// @include bp(md) { @content }
@mixin font-print($map) {
    // loops $map provided in mixing
    @each $font-declared in $map {
        //putting group key into variable [small, medium, large]
        $breakpoint: nth($font-declared, 1);
        //putting object into a variable in order to loop.
        $head-object: nth($font-declared, 2);

        //adding logic to render header styles in breakpoint
        @if $breakpoint !=sm {
            @include bp(#{$breakpoint}) {
                @each $head in $head-object {
                    $tag: nth($head, 1);
                    $tag-map: nth($head, 2);

                    .#{$tag} {
                        @each $key, $value in $tag-map {
                            #{$key}: #{$value};
                        }
                    }
                }
            }

            // logic to render without breakpoint
        } @else {
            @each $head in $head-object {
                $tag: nth($head, 1);
                $tag-map: nth($head, 2);

                .#{$tag} {
                    @each $key, $value in $tag-map {
                        #{$key}: #{$value};
                    }

                    p {
                        font-size: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                        letter-spacing: inherit;
                    }
                }
            }
        }
    }
}

// Px to Rem
// =================================
// usage:
// @include fontSize(20px);

@mixin font-size($size) {
    font-size: f.calculate-rem($size);
}

@mixin h1alt {
    font-family: v.$font-alt;
    font-weight: calculate-font-weight('black', v.$font-alt);
    text-transform: uppercase;
    line-height: 1.05;

    @include bp(md) {
        line-height: 1.02;
    }

    @include bp(xl) {
        @include font-size(66px);
        line-height: 1;
    }
}

@mixin link-underline-properties {
    text-decoration: underline;
    text-underline-offset: 3px; // won't work in IE11 and older Edge Browers
    text-decoration-thickness: 2px; // won't work in IE11 and older Edge Browers
    text-decoration-color: inherit; // won't work in IE11 and older Edge Browers
}

@mixin unset-link-underline-properties {
    text-underline-offset: 1px;
    text-decoration-thickness: 1px;
}

// Get Font-Weight
// =================================
// requires : font-weight parameter
// optional : font-family but will default to base font if not included
// usage:
// calcualte-font-weight([font wegith name], [font family name]);
@mixin set-font-weight($weight, $font: v.$font-standard) {
    font-weight: f.calculate-font-weight($weight, $font, v.$fonts-weight);
}

@mixin no-bullet {
    list-style-type: none;

    &:not(.row) {
        margin: 0;
        padding: 0;
    }
}

// Converts one of our named variable colors into an rgb
@function toRGB($color) {
    @return 'rgb(#{red($color)},#{green($color)},#{blue($color)})';
}

// Our most commonly used horizontal rule
@mixin hr {
    border: 0;
    border-top: 1px solid $gray-medium;
}

@mixin group-spacing {
    // Group/page-level Content Section/<section> always alternate background colors
    padding-top: map-get(v.$page-layout-spacing, 'short');
    padding-bottom: map-get(v.$page-layout-spacing, 'short');

    @include bp(lg) {
        padding-top: map-get(v.$page-layout-spacing, 'grande');
        padding-bottom: map-get(v.$page-layout-spacing, 'grande');
    }

    @include bp(xl) {
        padding-top: map-get(v.$page-layout-spacing, 'trenta');
        padding-bottom: map-get(v.$page-layout-spacing, 'trenta');
    }
}

// vertical spacing
// molecule-spacing-simple is the base rules for molecule spacing.
// use this in molecule-spacing and add complexity there.
// use this alone when you have molecules inside other molecules,
@mixin molecule-spacing-simple {
    padding-bottom: map-get(v.$page-layout-spacing, 'tiny');

    @include bp(lg) {
        padding-bottom: map-get(v.$page-layout-spacing, 'short');
    }

    @include bp(xl) {
        padding-bottom: map-get(v.$page-layout-spacing, 'grande');
    }
}

@mixin mixtape-spacing-simple {
    padding-bottom: map-get(v.$page-layout-spacing, 'mixtape');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $grid-breakpoints: $breakpoints) {
    $min: breakpoint-min($name, $grid-breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    576px
@function breakpoint-min($name, $grid-breakpoints: $breakpoints) {
    $min: map-get($grid-breakpoints, $name);
    @return if($min !=0, $min, null);
}

// Creates CSS grid column classes based on the columns and breakpoints
// >> col-small-1, .col-medium-2,...
// >> offsets: .col-start-small-1,...
@mixin make-cssgrid($columns: v.$grid-columns, $grid-breakpoints: v.$breakpoints) {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            @for $i from 1 through $columns {
                .col-#{$breakpoint}-#{$i} {
                    grid-column: auto / span $i;
                }
            }

            // Start with `1` because `0` is and invalid value.
            // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
            @for $i from 1 through ($columns - 1) {
                .col-start-#{$breakpoint}-#{$i} {
                    grid-column-start: $i;
                }
            }

            // Gnerates CSS classes for the order property in the CSS grid component based on the specified breakpoints
            @for $i from 1 through $columns {
                .col-order-#{$breakpoint}-#{$i} {
                    order: $i;
                }
            }
        }
    }
}

// Creates CSS grid spacing classes based on the spacing px numbers and breakpoints
// >> .grid-row-gap-sm-2, .grid-row-gap-md-4,...
@mixin make-cssgrid-spacings($grid-breakpoints: v.$breakpoints, $grid-spacings: v.$spacings) {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            @each $grid-spacing in $grid-spacings {
                .grid-row-gap-#{$breakpoint}-#{$grid-spacing} {
                    grid-row-gap: #{$grid-spacing}px;
                }
            }
        }
    }
}

// Create Grid based on grid config
// =================================
// usage:
// @include grid-container();
@mixin grid-container() {
    display: grid;
    max-width: v.$layout-max-width;
    width: 100%;
    grid-template-columns: repeat(v.$grid-columns, 1fr);
    grid-column-gap: v.$grid-column-gap;
    justify-content: center;
    padding-left: map-get(v.$grid-paddings, xs);
    padding-right: map-get(v.$grid-paddings, xs);
    margin: 0 auto;

    @include bp(sm) {
        padding-left: map-get(v.$grid-paddings, sm);
        padding-right: map-get(v.$grid-paddings, sm);
    }

    @include bp(md) {
        padding-left: map-get(v.$grid-paddings, md);
        padding-right: map-get(v.$grid-paddings, md);
    }

    @include bp(lg) {
        padding-left: map-get(v.$grid-paddings, lg);
        padding-right: map-get(v.$grid-paddings, lg);
    }

    .grid-container {
        padding-left: 0;
        padding-right: 0;
    }

    .col {
        grid-column: auto/span v.$grid-columns;
    }
}

@mixin match-grid-responsive-spacing($properties...) {
    @each $property in $properties {
        @each $size, $_ in $grid-paddings {
            @include bp($size) {
                #{$property}: map-get($grid-paddings, $size);
            }
        }
    }
}

@mixin alignment-classes {
    .align-items-stretch {
        align-items: stretch;
    }

    .align-items-center {
        align-items: center;
    }

    .align-items-start {
        align-items: flex-start;
    }

    .align-items-end {
        align-items: flex-end;
    }

    .align-self-stretch {
        align-self: stretch;
    }

    .align-self-center {
        align-self: center;
    }

    .align-self-start {
        align-self: start;
    }

    .align-self-end {
        align-self: flex-end;
    }
}

// make an element stretch the width of the window
// even if it's in a restricted-width container, like a content-container
@mixin bleed($passed-breakpoint) {
    @media (max-width: #{(map-get(v.$breakpoints, $passed-breakpoint ) - 1px)}) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
    }
}

@mixin web2PageOverrides {
    @if & {
        :global(.w2) & {
            @content;
        }
    } @else {
        :global(.w2) {
            @content;
        }
    }
}
