@use 'src/styles/global' as g;

@function get-promoBanner-header-styles($viewportSize, $tag) {
    @return (promoHeader: g.map-deep-get(g.$header-styles, $viewportSize, $tag));
}

$header-style-promoBanner-overrides: (
    sm: get-promoBanner-header-styles(sm, h4),
    md: get-promoBanner-header-styles(md, h3),
    lg: get-promoBanner-header-styles(lg, h4),
    xl: get-promoBanner-header-styles(xl, h4),
);

.promoBannerHeaderOverrides {
    @include g.font-print($header-style-promoBanner-overrides);
}

.wrapper.spacingOverride {
    height: 100%;

    div {
        padding: 0;
    }

    > div {
        padding: 12px;
        height: 100%;

        @include g.bp(sm) {
            padding: 24px;
        }

        @include g.bp(lg) {
            padding: 56px 40px;
        }
    }
}

.promoHeader {
    @include g.font-size(32px);
}
