@use '@/styles/global' as g;

$SiriusXM-Antares-Red: rgba(237, 0, 0, 1);
$SiriusXM-Aldebaran-Orange: rgba(255, 97, 41, 1);
$SiriusXM-Acrux-Indigo: rgba(105, 0, 255, 1);
$SiriusXM-Rigel-Cyan: rgba(49, 200, 255, 1);
$SiriusXM-Sirius-Dark-Grey: rgba(0, 0, 0, 0.9);

$Image-Overlay-Gradient: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 65.5%);
$Image-Overlay-Gradient-Reverse: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 65.5%);

$tablet: 768px;
$small-screen: 1175px;

.main_highlight,
.sub_highlight_left,
.sub_highlight_right {
    width: 100%;
    position: relative;
}

.h3 {
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: 2.25rem;

    @include g.bp-max(md) {
        font-size: 1.5rem;
        font-weight: 800;
        line-height: 1.5rem;
    }
}

.p1,
.p2 {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.container {
    display: flex;
    padding: min(4.5vw, 80px) 0;
    flex-direction: column;
    align-items: flex-start;
    gap: min(2vw, 80px);
    color: g.$white;
}

.image {
    display: flex;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    z-index: -2;
}

.overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $Image-Overlay-Gradient;

    @include g.bp-max(md) {
        display: none;
    }
}

.heading {
    align-self: stretch;
    display: flex;
    padding: 0px min(7vw, 108px);
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    @include g.bp-max(md) {
        padding: 0px 16px;
        flex-direction: column;
        gap: 8px;
    }
}

.body {
    display: flex;
    padding: 0px min(7vw, 108px);
    align-items: center;
    align-self: stretch;

    @include g.bp-max(md) {
        padding: 0px 16px;
    }
}

.exploremore {
    display: flex;
    height: 48px;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.exploremore a {
    text-decoration: none;
    color: g.$black;
}

.button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    align-self: stretch;
    background-color: g.$white;
}

.arrow {
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
}

.blackspacer {
    width: 28px;
    align-self: stretch;
    background-color: g.$black;
}

.colorspacer {
    width: 80px;
    align-self: stretch;
}

.main_highlight {
    .container {
        aspect-ratio: 7/5;
    }
    .image {
        aspect-ratio: 7/5;
    }
    .colorspacer {
        background-color: $SiriusXM-Acrux-Indigo;
    }
}

.sub_highlight_left {
    .container {
        aspect-ratio: 28/17;
    }
    .image {
        aspect-ratio: 28/17;
    }
    .colorspacer {
        background-color: $SiriusXM-Rigel-Cyan;
    }
}

.sub_highlight_right {
    .container {
        aspect-ratio: 28/17;
    }
    .image {
        aspect-ratio: 28/17;
    }
    .exploremore {
        left: -1px;
    }
    .colorspacer {
        background-color: $SiriusXM-Antares-Red;
    }
    .overlay {
        background-image: $Image-Overlay-Gradient-Reverse;
    }
}

@media screen and (max-width: $small-screen) and (min-width: $tablet) {
    .sub_highlight_left,
    .sub_highlight_right {
        .image,
        .container {
            aspect-ratio: auto;
            height: 350px;
        }
    }
    .main_highlight {
        .image,
        .container {
            aspect-ratio: auto;
            height: 411px;
        }
    }
}

@include g.bp-max(md) {
    .main_highlight,
    .sub_highlight_left,
    .sub_highlight_right {
        background-color: $SiriusXM-Sirius-Dark-Grey;
        padding: 16px 0px;
        .image {
            position: relative;
            z-index: 0;
            aspect-ratio: 15/8;
        }
        .container {
            aspect-ratio: 15/8;
        }
    }
    .container {
        display: flex;
        padding: 0 0 16px 0;
        justify-content: center;
        gap: 16px;
    }

    .p1 {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25rem;
    }
    .p2 {
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.25rem;
    }

    .exploremore {
        position: static;
        height: auto;
        line-height: 1.25rem;
        .blackspacer {
            display: none;
        }
        .colorspacer {
            display: none;
        }
    }

    .mobileoverlay {
        z-index: 2;
        width: 100%;

        .rectangle1 {
            position: absolute;
            width: 32px;
            height: 48px;
        }
        .rectangle2 {
            display: flex;
            align-items: flex-start;
            position: absolute;
            .x,
            .y {
                align-self: stretch;
            }
        }
    }

    .main_highlight,
    .sub_highlight_left {
        .rectangle1 {
            right: 0;
            background-color: g.$white;
        }
        .rectangle2 {
            width: 16px;
            bottom: 0;
            flex-direction: column;
        }
        .x {
            height: 40px;
        }
        .y {
            height: 24px;
            background-color: g.$white;
        }
    }

    .main_highlight {
        .x {
            background-color: $SiriusXM-Acrux-Indigo;
        }
    }

    .sub_highlight_left {
        .x {
            background-color: $SiriusXM-Rigel-Cyan;
        }
    }

    .sub_highlight_right {
        .rectangle1 {
            top: 0;
            background-color: g.$white;
        }
        .rectangle2 {
            height: 16px;
            bottom: 0;
            right: 0;
            flex-direction: row-reverse;
        }
        .y {
            width: 32px;
            background-color: g.$black;
        }
        .x {
            width: 64px;
            background-color: $SiriusXM-Antares-Red;
        }
        .exploremore {
            justify-content: flex-end;
        }
    }
}
