@use '@/styles/global' as g;

.container {
    display: flex;
    flex-direction: column;

    a {
        display: none;

        @include g.bp(md) {
            display: block;
        }
    }
}

.genres {
    text-align: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}

.buttonWrapper {
    margin-right: 10px;
    margin-bottom: 10px;
}

.mobileLink {
    a {
        display: block;

        @include g.bp(md) {
            display: none;
        }
    }

    margin-top: 24px;
}

.linkOnLeft {
    a {
        display: block;
    }

    @include g.bp(md) {
        margin: 24px auto;
    }

    margin-top: 24px;
}