// Color Palette
// -------------

// Primary
$black: #000;
$blue: #0000eb;
$white: #fff;

// Secondary
$off-white: #f5f5f5;
$off-white-2: #eee;
$off-white-dark: #a4a1ae;
$gray-light: #eaeaea;
$gray-medium: #d4d4d4;
$gray-medium-light: #c4c4c4;
$blue-ultra-light: #f1f5fd;
$blue-light: #e9eef8;

//Deep Gray
$gray-dark: #736e7d;
$gray-semi-dark: #979797;
$gray-charcoal: #736e7e;
$silver: #b0b0b0;

$medium-black: #666666;
$almost-black: #333;
$midnight: #22272d;
$blacktwentypercent: rgba(0, 0, 0, 0.2);
$black-seventy-percent: rgba(0, 0, 0, 0.7);
$blue-secondary: #006ed7;
$solitude-blue: #e8eef8;
$cornflower: #548bed;
$purple: #322790;
$hover-purple: #5b27a1;
$ltpurple: #96f;
$dkpurple: #261d6d;
$mine-shaft: #2d2a2b;
$mine-shaft-trans: rgba($mine-shaft, 0.7);
$dark-charcoal: #272e3c;
//Teal Electric
$green-light: #00e3b9;
$raspberry: #c5169c;
$highlighter: #d9f846;
$cyan: #00e0fa;

//error red
$red: #eb0a0a;

//confirmation green
$green: #177a31;

//warning orange
$orange: #efa30a;

//vanilla off-white
$vanilla: #fff9d5;

// Misc
$modal-background: rgba($black, 0.8);

$background-white: $white;
$background-grey: $gray-medium;
$black-trans: rgba($black, 0.15);
$white-trans: rgba($white, 0.15);
$white-trans-dark: rgba($white, 0.8);
$black-drop-hover: rgba($black, 0.07);
$white-drop-hover: rgba($white, 0.07);
$white-drop-selected: rgba($white, 0.14);
$package-summary-blue: #322790;
$black-rgba: rgba(0, 0, 0, 0);
$black-rgba-opaque: rgba(0, 0, 0, 4);
$focus-blue: #5e9ed6;
$focus-blue-trans: rgba($focus-blue, 0.75);
$hover-blue: #2c6fd0;
$skeleton-bone-base: #e7e7e7;

// Common colors
$color-text: $black;
$color-bg: #f1f1f1;
$color-border: #ddd;

// <hr> border color
$hr-color: lighten($black, 70%);
$gold: #fdb913;

//logo
$logo-waves: #2aace3;
$logo-text: #003a5d;

//box shadows
$active-shadow: 0 2px 15px -4px rgba(0, 0, 0, 0.15);
$hover-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
$tooltip-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
$active-shadow-heavy: 0 15px 30px -10px $blacktwentypercent;

//homepage gradient
$dark-gradient-part: rgba(0, 0, 0, 0.4);

//grid themes
$light-yellow: #fffdf3;
$light-yellow-sub: #fff9dd;
$light-green: #fafff8;
$light-green-sub: #f1ffeb;
$light-purple: #f6f5ff;
$light-purple-sub: #e8e5ff;

// default hero background
$bgBlue: #0a2343;

// atlas-light
$secondary-atlas-web-blue: #0f63ff;
$secondary-light-gray: #e4e9ec;
$secondary-medium-gray: #d0d8dd;
$secondary-deep-gray: #607380;
$secondary-ultra-light-blue: #f2f8fc;
