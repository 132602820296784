@use './mixins' as m;
@use './variables' as v;
@use './functions' as f;
@use 'src/styles/global' as g;

// adding all headers h1-h6
@include m.font-print(v.$header-styles);

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: inherit;
}

h1 {
    line-height: 1;

    @include g.bp(lg) {
        line-height: 1.1;
    }
}

h2 {
    line-height: 1.1;
}

h3 {
    line-height: 1.09;
}

h4 {
    line-height: 1.08;

    @include g.bp(lg) {
        line-height: 1.09;
    }
}

h5 {
    line-height: 1.1;

    @include g.bp(lg) {
        line-height: 1.08;
    }
}

h6 {
    line-height: 1.12;

    @include g.bp(lg) {
        line-height: 1.11;
    }
}

a {
    font-size: inherit;
    font-weight: inherit;
    color: var(--base-contrast-accent);
    cursor: pointer;
    white-space: normal;
    position: relative;
}

// LEAVIN THIS FOR NOW - Attilio

// p,
// .p,
// .paragraph {
//     display: block;
//     margin-top: 0;
//     margin-bottom: 12px;
//     line-height: 1.5;
//     @include m.font-size(16px);

//     &.text-xlarge {
//         margin-bottom: 14px;
//     }

//     &:last-child {
//         margin-bottom: 0;
//     }
// }

// b,
// strong,
// .text-bold {
//     @include m.font-weight('bold');
// }
