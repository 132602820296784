@use '@/styles/global' as g;

.wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px 24px;
    padding: 0 0 8px;

    &.linkOnRight {
        flex-direction: column-reverse;
    }

    @include g.bp(md) {
        flex-direction: row;
        padding: 0;

        &.linkOnRight {
            flex-direction: row-reverse;
        }
    }
}

.description {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.link {
    display: flex;
    align-items: center;
    flex: 0 0 calc(30% - 24px);
    line-height: 1.57;

    a {
        text-decoration: none;
        font-weight: bold;
    }
}