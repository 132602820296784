@use './generated/_base';
@use './generated/_colors-light';
@use './generated/_typography-small';
// Global dark mode not yet implemented:
// @use './generated/_colors-dark';
// These currently match typography-small but may be used in the future:
// @use './generated/_typography-large';

@use './mixins' as m;
@use './cssvars-temp';

html {
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    @include m.base-font-styles;
    @include m.box-sizing-reset;
    @include m.base-image-styles;
    @include m.button-reset;
    @include m.cursor-styles;
    @include m.section-styles;
}

.word {
    display: inline-block;
    overflow: hidden;
    line-height: 1.2;
    margin-bottom: -0.15em !important;
}
