@use '@/styles/global' as g;

$SiriusXM-Aldebaran-Orange: #ff6129;
$SiriusXM-Acrux-Indigo: #6900ff;
$SiriusXM-Rigel-Cyan: #31c8ff;
$Linear-Gradient: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));

$tablet: 768px;
$mobile: 540px;

.body {
    display: flex;
    padding: 40px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    background-color: g.$off-white;

    @include g.bp-max(md) {
        width: 100%;
        padding: 16px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
}

.heading {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    @include g.bp-max(md) {
        align-items: flex-start;
    }
}

.heading h4 {
    @include g.font-size(16px);
    @include g.set-font-weight('black');
    line-height: 1.25rem;

    @include g.bp(md) {
        @include g.font-size(28px);
        line-height: 1.75rem;
    }
}

.heading p {
    @include g.font-size(16px);
    line-height: 1.25rem;

    @include g.bp(md) {
        @include g.font-size(18px);
        line-height: 1.5rem;
    }
}

.module {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    @include g.bp-max(md) {
        gap: 8px;
    }   
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
}

.containerItem {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 4vw;
    flex: 1 0 0;
    padding: 0 min(2.75vw, 54px);
}

.image {
    display: flex;
    height: max(100%, 335px);
    aspect-ratio: 16/9;
    position: relative;
    width: max(10vw, 48%);
    @include g.bp-max(md) {
        width: 49.5%;
    }
}

.mobileBackground {
    display: none;
}

.overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $Linear-Gradient;
}

.info {
    display: flex;
    min-height: 138px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;

    @include g.bp-max(lg) {
        gap: 8px;
        align-self: stretch;    
    }
}

.info p {
    @include g.font-size(14px);
    line-height: 1.125rem;

    @include g.bp(lg) {
        @include g.font-size(20px);
        line-height: 1.5rem;
    }
}

.cta {
    display: flex;
    gap: 14px;
    color: g.$blue;
    text-decoration: none;
    @include g.font-size(14px);
    line-height: 1.125rem;

    @include g.bp(lg) {
        @include g.font-size(18px);
        line-height: 1.5rem;
    }
}

.arrow {
    width: 14px;
    height: 14px;
    display: flex;
    align-self: center;
    padding-top: 1px;

}

.arrow_image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 14px;
    height: 14px;
    align-self: stretch;
    margin: auto 0;
}

.colorBlock {
    width: 56px;
    align-self: stretch;
    width: min(2.75vw, 54px);

    @include g.bp-max(md) {
        display: none;
    }
}

.button {
    display: flex;
    max-width: 480px;
    padding: 12px 16px;
    align-items: center;
    gap: 16px;
    background-color: $SiriusXM-Acrux-Indigo;
    border: 1px solid $SiriusXM-Acrux-Indigo;
    color: g.$white;
    text-decoration: none;
    @include g.font-size(16px);
    @include g.set-font-weight('bold');
    line-height: 1.25rem;
}

.first {
    display: flex;
    align-self: flex-start;
    width: 100%;

    .container {
        padding-right: min(2.75vw, 54px);
    }

    .colorBlock {
        background-color: $SiriusXM-Acrux-Indigo;
    }

    .image {
        align-items: flex-end;      
    }

    .info {
        padding-bottom: var(--font-height-XL, 64px);
    }

    .mobileBackground, .desktopBackground {
        @include g.bp-max(md) {
            border-left: solid $SiriusXM-Acrux-Indigo 16px;
        }
    }
}

.second {
    display: flex;
    align-self: flex-end;
    margin-top: -32px;
    width: 100%;

    .container {
        padding-left: min(2.75vw, 54px);
    }

    .colorBlock {
        background-color: $SiriusXM-Aldebaran-Orange;
    }

    .image {
        align-items: flex-start;
    }

    .mobileBackground, .desktopBackground {
        @include g.bp-max(md) {
            border-right: solid $SiriusXM-Aldebaran-Orange 16px;
        }
    }
}

.third {
    display: flex;
    align-self: flex-start;
    margin-top: -32px;
    width: 100%;

    .container {
        padding-right: min(2.75vw, 54px);
    }

    .colorBlock {
        background-color: $SiriusXM-Rigel-Cyan;
    }

    .image {
        align-items: flex-end;      
    }

    .info {
        padding: 64px 0px 32px 0px;
    }

    .mobileBackground, .desktopBackground {
        @include g.bp-max(md) {
            border-left: solid $SiriusXM-Rigel-Cyan 16px;
        }
    }
}

@media screen and (max-width: $mobile) {
    .image {
        display: flex;
        aspect-ratio: auto;
        flex-direction: column;
    }

    .desktopBackground {
        display: none;
    }

    .mobileBackground {
        display: flex;
        object-fit: cover;
        width: 100%;
        height: 100%;

    }
    
}

@media screen and (max-width: $tablet) {
    .first, .second, .third {
        margin: 0px;

        .info {
            padding: min(1vw, 24px) 16px;
        }

        .container {
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            padding: 0;
            width: 100vw;
        }
    
        .containerItem {
            padding: 0px;
            gap: 0px;
        }
    
    }
    
}