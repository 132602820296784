@use 'src/styles/global' as g;

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 36px;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;

    @include g.bp(md) {
        width: auto;
    }

    img {
        max-height: 40px;
        margin-bottom: 8px;
    }

    p {
        @include g.set-font-weight('bold');
        @include g.font-size(14px);
        text-align: center;
    }
}