@use '@/styles/global' as g;

.text {
    &:last-child {
        margin-bottom: 15px;
    }

    &,
    a {
        @include g.font-size(14px);
        text-decoration: none;
        margin-bottom: 17px;
    }
}
