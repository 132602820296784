@use '@/styles/global' as g;

.mainWrapper {
    .featureGroupItemsWrapper {
        padding: 0px;
        margin: 0;

        .listItem {
            list-style: none;

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            &.bottomBorder {
                border-bottom: 1px solid g.$off-white-dark;
                padding-bottom: 24px;
            }
        }
    }
}

.featuredGroupItem {
    @include g.bp(md) {
        justify-content: flex-start;
    }

    .imageWrapper {
        img {
            width: auto;
            height: auto;
            max-width: 160px;
            max-height: 80px;
            margin-bottom: 24px;

            @include g.bp(md) {
                margin-bottom: 0px;
                max-width: 100%;
                max-height: 100px;
                margin: 0 auto;
            }
        }
    }

    .textWrapper {
        display: flex;
        flex-direction: column;

        .itemTitle {
            @include g.set-font-weight('bold');
        }

        .linkStyles {
            margin-top: 6px;
        }
    }
}