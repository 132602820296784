@use '@/styles/global' as g;

$Image-Border-1: linear-gradient(#ffd217 50%, #f5f5f5 50% 75%, g.$black 75%);
$Image-Border-2: linear-gradient(#f5f5f5 33%, g.$black 33% 53%, #31c8ff 53%);

.mainWrapper {
    display: flex;
    padding: 40px min(7.5vw,108px);;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    @include g.bp-max(lg) {
        padding: 40px min(3.5vw,108px);
    }

    @include g.bp-max(md) {
        display: flex;
        width: 100%;
        padding: 0 0 16px;
    }
}

.arrow {
    display: flex;
    align-self: stretch;
    align-items: center;
    padding-top: 1px;
}

.arrow img {
    width: 12px;
    height: 12px;
    vertical-align: middle;

    @include g.bp(md) {
        width: 14px;
        height: 14px;
    }
}

.container {
    display: flex;
    align-items: center;
    gap: 32x;
    align-self: stretch;
    width: 100%;

    @include g.bp-max(md) {
        align-items: center;
        gap: 0;
    }
}

.image {
    display: flex;
    width: 60%;
    align-items: flex-start;
    position: relative;
    aspect-ratio: 16/9;
    height: auto;

    img {
        filter: brightness(75%);
        @include g.bp-max(md) {
            object-fit: contain;
            width: 100%;
        }
        
    }

    @include g.bp-max(sm) {
        aspect-ratio: auto;
        width: 49.5%;
        object-fit: cover;
    }
}

.border {
    height: 100%;
    width: 16px;
    position: absolute;
    z-index: 2;

    @include g.bp-max(md) {
        width: 8px;
    }
}

.left {
    background-image: $Image-Border-1;
}

.right {
    background-image: $Image-Border-2;
    right: 0;
}

.logo {
    display: flex;
    padding: 0 8px;
    justify-content: left;
    align-items: center;
    gap: 18%;
    align-self: stretch;

    @include g.bp-max(md) {
        padding: 0 4px;
        gap: 16px;
        justify-content: space-between;
    }

    * {
        width: 5.5vw;

        @include g.bp-max(md) {
            display: flex;
            width: 36px;
            justify-content: center;
            align-items: center;
        }
    }
}

.info {
    display: flex;
    padding: 0 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;

    @include g.bp-max(lg) {
        padding: 0px 16px;
        gap: 8px;
    }
}

.info h3 {
    text-transform: uppercase;
    @include g.font-size(20px);
    @include g.set-font-weight('bold');
    line-height: 1.5rem;

    @include g.bp(md) {
        @include g.font-size(32px);
        @include g.set-font-weight('black');
        line-height: 2.25rem;
    }
}

.info h5 {
    @include g.font-size(14px);
    line-height: 1.25rem;
    @include g.set-font-weight('light');

    @include g.bp(md) {
        @include g.font-size(20px);
        line-height: 1.5rem;
        @include g.set-font-weight('normal');
    }
}

.desktopBackground {
    display: flex;

    @include g.bp-max(sm) {
        display: none;
    }
}

.mobileBackground {
    display: none;

    @include g.bp-max(sm) {
        display: flex;
    }
}

.cta {
    display: flex;
    align-items: center;
    gap: 16px;
    color: g.$blue;
    text-decoration: none;
    @include g.font-size(14px);
    line-height: 1.125rem;

    @include g.bp(md) {
        @include g.font-size(18px);
        line-height: 1.5rem;
    }

    @include g.bp-max(md) {
        display: flex;
        align-items: center;
        gap: 0.875rem;
        align-self: stretch;
    }
}

