// TO DO: migrate other necessary mixins from react-lib

@use 'sass:math';
@use './functions' as f;
@use './variables' as v;

//Global mixins
@mixin box-sizing-reset {
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
}

@mixin base-font-styles {
    color: var(--base-contrast);
    font-family: var(--font-standard);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // TO DO: update this to correct CSS vars
    // We'll have to update calc-rem mixin to accept CSS vars
    @include font-size(14px);
    line-height: var(--temp-line-height-paragraph-medium);
}

@mixin base-image-styles {
    img {
        display: block;
        height: auto;
        width: 100%;
    }
}

@mixin button-reset {
    button {
        background: transparent;
        border-color: transparent;
        padding: 0;
        margin: 0;
        font-family: var(--font-standard);
        color: inherit;
    }
}

@mixin cursor-styles {
    a,
    button {
        cursor: pointer;
    }
}

@mixin section-styles {
    // <section> === "Content Group" in CMS, "Group" in design
    section {
        @include group-spacing;

        &.no-padding {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

// Breakpoint
// =================================
// usage:
// @include bp(lg) { @content }
@mixin bp($breakpoint) {
    @if map-has-key(v.$breakpoints, $breakpoint) {
        @media (min-width: #{map-get(v.$breakpoints, $breakpoint)}) {
            @content;
        }
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.';
    }
}

// Breakpoint max-width (use in rare cases)
// =================================
// usage:
// @include bp-max(md) { @content }
@mixin bp-max($breakpoint) {
    @if map-has-key(v.$breakpoints, $breakpoint) {
        @media (max-width: #{map-get(v.$breakpoints, $breakpoint)-1}) {
            @content;
        }
    } @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.';
    }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $grid-breakpoints: $breakpoints) {
    $min: breakpoint-min($name, $grid-breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(md, (sm: 0, md: 768px, lg: 1024px, xl: 1440px, xxl: 1920px))
//    768px
@function breakpoint-min($name, $grid-breakpoints: $breakpoints) {
    $min: map-get($grid-breakpoints, $name);
    @return if($min !=0, $min, null);
}

// Font Declaration
// =================================
@mixin font-print($map) {
    // loops $map provided in mixing
    @each $font-declared in $map {
        //putting group key into variable [small, medium, large]
        $breakpoint: nth($font-declared, 1);
        //putting object into a variable in order to loop.
        $head-object: nth($font-declared, 2);

        //adding logic to render header styles in breakpoint
        @if $breakpoint !=md {
            @include bp(#{$breakpoint}) {
                @each $head in $head-object {
                    $tag: nth($head, 1);
                    $tag-map: nth($head, 2);

                    .#{$tag} {
                        @each $key, $value in $tag-map {
                            #{$key}: #{$value};
                        }
                    }
                }
            }

            // logic to render without breakpoint
        } @else {
            @each $head in $head-object {
                $tag: nth($head, 1);
                $tag-map: nth($head, 2);

                .#{$tag} {
                    @each $key, $value in $tag-map {
                        #{$key}: #{$value};
                    }

                    p {
                        font-size: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                        letter-spacing: inherit;
                    }
                }
            }
        }
    }
}

@mixin font-size($size) {
    font-size: f.calculate-rem($size);
}

@mixin font-weight($weight) {
    font-weight: f.calculate-font-weight($weight, v.$fonts-weight);
}

@mixin no-bullet {
    list-style-type: none;

    &:not(.row) {
        margin: 0;
        padding: 0;
    }
}

// Create Grid based on grid config
// =================================
// usage:
// @include grid-container();
@mixin grid-container() {
    display: grid;
    max-width: v.$layout-max-width;
    width: 100%;
    grid-template-columns: repeat(v.$grid-columns, 1fr);
    grid-column-gap: v.$grid-column-gap;
    justify-content: center;
    padding-left: map-get(v.$grid-paddings, sm);
    padding-right: map-get(v.$grid-paddings, sm);
    margin: 0 auto;

    @include bp(md) {
        padding-left: map-get(v.$grid-paddings, md);
        padding-right: map-get(v.$grid-paddings, md);
    }

    @include bp(lg) {
        padding-left: map-get(v.$grid-paddings, lg);
        padding-right: map-get(v.$grid-paddings, lg);
    }

    @include bp(xl) {
        padding-left: map-get(v.$grid-paddings, xl);
        padding-right: map-get(v.$grid-paddings, xl);
    }

    .grid-container {
        padding-left: 0;
        padding-right: 0;
    }

    .col {
        grid-column: auto/span v.$grid-columns;
    }
}

// Creates CSS grid spacing classes based on the spacing px numbers and breakpoints
// >> .grid-row-gap-md-2, .grid-row-gap-lg-4,...
@mixin make-cssgrid($columns: v.$grid-columns, $grid-breakpoints: v.$breakpoints) {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            @for $i from 1 through $columns {
                .col-#{$breakpoint}-#{$i} {
                    grid-column: auto / span $i;
                }
            }

            // Start with `1` because `0` is and invalid value.
            // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
            @for $i from 1 through ($columns - 1) {
                .col-start-#{$breakpoint}-#{$i} {
                    grid-column-start: $i;
                }
            }

            // Gnerates CSS classes for the order property in the CSS grid component based on the specified breakpoints
            @for $i from 1 through $columns {
                .col-order-#{$breakpoint}-#{$i} {
                    order: $i;
                }
            }
        }
    }
}

// Creates CSS grid spacing classes based on the spacing px numbers and breakpoints
// >> .grid-row-gap-md-2, .grid-row-gap-lg-4,...
@mixin make-cssgrid-spacings($grid-breakpoints: v.$breakpoints, $grid-spacings: v.$spacings) {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            @each $grid-spacing in $grid-spacings {
                .grid-row-gap-#{$breakpoint}-#{$grid-spacing} {
                    grid-row-gap: #{$grid-spacing}px;
                }
            }
        }
    }
}

@mixin match-grid-responsive-spacing($properties...) {
    @each $property in $properties {
        @each $size, $_ in v.$grid-paddings {
            @include bp($size) {
                #{$property}: map-get(v.$grid-paddings, $size);
            }
        }
    }
}

@mixin alignment-classes {
    .align-items-stretch {
        align-items: stretch;
    }

    .align-items-center {
        align-items: center;
    }

    .align-items-start {
        align-items: flex-start;
    }

    .align-items-end {
        align-items: flex-end;
    }

    .align-self-stretch {
        align-self: stretch;
    }

    .align-self-center {
        align-self: center;
    }

    .align-self-start {
        align-self: start;
    }

    .align-self-end {
        align-self: flex-end;
    }
}

// Vertical spacing
@mixin group-spacing {
    padding-top: map-get(v.$page-layout-spacing, 'short');
    padding-bottom: map-get(v.$page-layout-spacing, 'short');

    @include bp(lg) {
        padding-top: map-get(v.$page-layout-spacing, 'venti');
        padding-bottom: map-get(v.$page-layout-spacing, 'venti');
    }

    @include bp(xl) {
        padding-top: map-get(v.$page-layout-spacing, 'gigantic');
        padding-bottom: map-get(v.$page-layout-spacing, 'gigantic');
    }
}

@mixin molecule-spacing {
    padding-bottom: map-get(v.$page-layout-spacing, 'tiny');

    @include bp(lg) {
        padding-bottom: map-get(v.$page-layout-spacing, 'short');
    }

    @include bp(xl) {
        padding-bottom: map-get(v.$page-layout-spacing, 'grande');
    }
}

.grid-container {
    margin-bottom: map-get(v.$page-layout-spacing, 'tiny');
    @include bp(lg) {
        margin-bottom: map-get(v.$page-layout-spacing, 'short');
    }
    @include bp(xl) {
        margin-bottom: map-get(v.$page-layout-spacing, 'grande');
    }
}
.grid-container:last-of-type {
    margin-bottom: 0;
}

// Our most commonly used horizontal rule
@mixin border-separator {
    border-bottom: var(--border-width-1) solid var(--base-surface-strong);
}

@function _getNavThemeSelectors($themeNames...) {
    $selectors: '';

    @each $themeName in $themeNames {
        $selectors: $selectors + '.nav-theme-#{$themeName} ';
    }

    @return $selectors;
}

@mixin navTheme($themeNames...) {
    @if & {
        :global(#{_getNavThemeSelectors($themeNames)}) & {
            @content;
        }
    } @else {
        :global(#{_getNavThemeSelectors($themeNames)}) {
            @content;
        }
    }
}

@mixin web2PageOverrides {
    @if & {
        :global(.w2) & {
            @content;
        }
    } @else {
        :global(.w2) {
            @content;
        }
    }
}
